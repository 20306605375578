type Newtail = {
  clickUrl: string
  destinationUrl: string
  impressionUrl: string
  viewUrl: string
}

type Flags = {
  isMarketplace: boolean
  isOpenbox: boolean
  isFreeShipping: boolean
  isFreeShippingPrime: boolean
  isPixShipping: boolean
  isPreOrder: boolean
  isPrime: boolean
  isFlash: boolean
  isOffer: boolean
  hasGift: boolean
  isHighlight: boolean
  isSponsored?: boolean
}

type Offer = {
  id: string
  code?: number
  name: string
  referenceBanner: string
  endsAt: number
  startsAt: number
  isPrimeExclusive: boolean
  isLoggedUserExclusive?: boolean
  price: number
  priceWithDiscount: number
  discountPercentage: number
  percentageDiscount?: number
  savePrime?: number
  quantityAvailable: number
  hashCode: string
  mainColor?: string
  textColor?: string
}

type Prime = {
  price: number
  priceWithDiscount: number
  discountPercentage: number
  save: number
  isLoggedUserExclusive?: boolean
  maxInstallmentPrime: string
}

type Colors = {
  main: string
  text: string
}

type Manufacturer = {
  id: number
  name: string
  img: string
}

type Installment = {
  id: number
  paymentTerms: string
  installment: number
  amount: number
  total: number
}

type PaymentMethod = {
  category: string
  method: string
  installments: Installment[]
}

type BannerModel = {
  type: string
  media: string
  hyperlink: string | null
}

type Photos = {
  p: string[]
  m: string[]
  g: string[]
  gg: string[]
}

type Origin = {
  id: number | null
  name: string | null
}

type Stamps = {
  id: number
  title: string
  name: string
  backgroundColor: string
  fontColor: string
  type: 'legendary' | 'blackLegendary' | 'normal'
}

type Menus = {
  code: number
  link: string
  name: string
}

export type Product = {
  code: number
  productSpecie: number
  name: string
  description: string
  tagDescription: string
  weight: number
  friendlyName: string
  sellerName: string
  menus?: Menus[]
  sellerId: number
  tagCode?: number
  offerIdMarketplace: number
  category: string
  externalUrl: string
  manufacturer: Manufacturer
  iframeUrl: string
  image: string
  images: string[]
  list?: string
  position?: number
  discountPercentagePrime: number
  oldPrice: number
  oldPrimePrice: number
  maxInstallment: string
  price: number
  primePrice: number
  primePriceWithDiscount: number
  priceWithDiscount: number
  priceMarketplace: number
  discountPercentage: number
  offerDiscount: number
  quantity: number
  rating: number
  available: boolean
  preOrderDate: number
  warranty: string
  dateProductArrived: number | null
  html: string | null
  ufsFlash: string[]
  stamps: Stamps | null
  photos: Photos | null
  flags: Flags
  origin: Origin
  offer: Offer | null
  prime: Prime | null
  colors: Colors
  paymentMethods?: PaymentMethod[]
  paymentMethodsDefault: null
  paymentMethodsPrime: null
  ratingNumber?: number
  ratingCount?: number
  thumbnail: string
  promotionBanner: BannerModel | null
  campaignBanners: BannerModel[] | null
  crossCart: number[] | []
  crossSelling: boolean | number[]
  newtail?: Newtail
}

export type Params = {
  query?: string
  flags?: string
  variant?: string
  page_number: number
  page_size: number
  facet_filters: string
  sort: string
  is_prime: boolean
}

export type Pagination = {
  prev: number
  current: number
  next: number
  total: number
}

type Filter = {
  name: string
  min?: number
  max?: number
  values?: string[] | boolean[]
  text?: string
}

export type Breadcrumb = {
  id: number
  name: string
  path: string
}
type Page = {
  cursor: string
  number: number
  size: number
  isCurrentPage: boolean
}

type Seo = {
  title: string
  description: string
  titleHeading: string
}

type Promotion = {
  title: string
  description: string
}

export type Meta = {
  totalItemsCount: number
  totalPagesCount: number
  page: Page
  filters: Filter[]
  breadcrumb?: Breadcrumb[]
  searchVariant?: string
  seo: Seo
  promotion: Promotion
}

export type Links = {
  redirect: string
  first: string
  self: string
  last: string
  next: string
}

export type Catalog = {
  meta: Meta
  links?: Links
  data: Product[]
  include?: any
  pagination: Pagination
  redirect?: string
}

export enum FilterType {
  DEFAULT = 'default',
  PAGINATION = 'pagination',
  BACK = 'back'
}

export enum ListingTypes {
  SEARCH = 'search',
  LISTING = 'listing',
  JUST_ARRIVED = 'just-arrived',
  MOST_SEARCHED = 'most-searched',
  PROMOTION = 'promotion',
  RELEASES = 'releases',
  HIGHLIGHTS = 'highlights',
  SHOWCASE = 'showcase',
  BRAND_SHOWCASE = 'brand-showcase',
  STORE_SHOWCASE = 'store-showcase',
  STORE_PAGE = 'store-page',
  BRAND_PAGE = 'brand-page'
}
