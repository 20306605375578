import styled from 'styled-components'

const Grid = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
`

export const PageGrid = styled(Grid)`
  padding: ${(props) => `${props.theme.spacing.xl} 0`};
`

export const ContentGrid = styled(Grid)`
  max-width: ${(props) => props.theme.rem(1248)};
  padding: ${(props) => `0 ${props.theme.spacing.sm}`};
`

export const CarouselGrid = styled(Grid)`
  max-width: ${(props) => props.theme.rem(1300)};
  padding: ${(props) => `0 ${props.theme.spacing.sm}`};
  margin-top: ${(props) => props.theme.rem(60)}; ;
`

export const Carousel = styled.div`
  padding: ${(props) => `0 ${props.theme.spacing.lg}`};
  margin-left: -${(props) => `${props.theme.spacing.md}`};
`

export const CarouselSkeletonWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > span {
    display: grid;
    grid-template-columns: repeat(6, ${(props) => props.theme.rem(214)});
    gap: ${(props) => props.theme.spacing.sm};
    margin-top: ${(props) => props.theme.spacing.lg};
  }
`
