import Head from 'next/head'
import { usePathname } from 'next/navigation'
import { KABUM_URL } from 'settings/kernel'
import * as SEO from 'settings/seo'
import { HeadProps } from './PageHead.types'

export default function PageHead({
  title = SEO.SITE_TITLE,
  metaTitle = SEO.SITE_TAG_TITLE,
  metaDescription = SEO.SITE_TAG_DESCRIPTION,
  metaImage = 'https://cdn.cookielaw.org/logos/786147c0-ae7b-45e8-af2b-6957a5af9fe0/e01396ef-c6fc-4c43-8a50-d66ab1a8448c/9f986df4-a13b-4f3e-add9-6f044ea6e47e/140.png',
  hasMetaOg = false,
  hasMetaTwitter = false,
  hasCanonical = true,
  children
}: HeadProps) {
  const pathname = usePathname()
  const getPageUrl = `${KABUM_URL}${pathname}`

  const og = () => {
    if (hasMetaOg) {
      return (
        <>
          <meta property='og:url' content={getPageUrl} />
          <meta property='og:type' content='website' />
          <meta property='og:title' content={metaTitle} />
          <meta property='og:description' content={metaDescription} />
          <meta property='og:image' content={metaImage} />
        </>
      )
    }
    return null
  }

  const twitter = () => {
    if (hasMetaTwitter) {
      return (
        <>
          <meta name='twitter:site' content='@kabumcombr' />
          <meta name='twitter:card' content='summary_large_image' />
          <meta property='twitter:domain' content='www.kabum.com.br' />
          <meta property='twitter:url' content={getPageUrl} />
          <meta name='twitter:title' content={metaTitle} />
          <meta name='twitter:description' content={metaDescription} />
          <meta name='twitter:image' content={metaImage} />
        </>
      )
    }
    return null
  }

  const canonical = () => {
    if (hasCanonical) {
      return <link rel='canonical' href={getPageUrl} />
    }
    return null
  }

  return (
    <Head>
      <title>{title}</title>
      <meta name='title' content={metaTitle} />
      <meta name='description' content={metaDescription} />
      {canonical()}
      {og()}
      {twitter()}
      {children}
    </Head>
  )
}
