const IconJustArrived = (props) => (
  <svg
    width="18"
    height="24"
    viewBox="0 0 18 24"
    fill="none"
    xmlns="https://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8768 0.102663C14.0236 0.18777 14.1372 0.320148 14.1991 0.478169C14.2609 0.63619 14.2673 0.810514 14.2173 0.972663L11.5158 9.75066H16.5003C16.6468 9.7506 16.7901 9.79344 16.9125 9.87389C17.0349 9.95434 17.1311 10.0689 17.1892 10.2034C17.2472 10.3378 17.2646 10.4864 17.2393 10.6306C17.2139 10.7749 17.1468 10.9086 17.0463 11.0152L5.0463 23.7652C4.93019 23.8886 4.77568 23.9691 4.60796 23.9935C4.44024 24.018 4.26919 23.9848 4.1227 23.8996C3.97622 23.8143 3.86292 23.682 3.80129 23.5241C3.73965 23.3662 3.73331 23.1921 3.7833 23.0302L6.4848 14.2507H1.5003C1.35382 14.2507 1.21052 14.2079 1.08811 14.1274C0.965693 14.047 0.869515 13.9325 0.811447 13.798C0.753379 13.6635 0.735963 13.5149 0.761349 13.3707C0.786735 13.2264 0.853812 13.0927 0.954299 12.9862L12.9543 0.236163C13.0703 0.112861 13.2245 0.0324155 13.392 0.00790543C13.5595 -0.0166046 13.7304 0.0162624 13.8768 0.101163V0.102663Z"
      fill={props.color || '#FF6500'}
    />
  </svg>
)

export { IconJustArrived as default } 