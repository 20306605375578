import type { Catalog, Product } from 'types/catalog'
import type { SponsoredProducts } from 'types/sponsoredProducts'
import { formatSponsoredProductsToCatalog } from 'utils/formatSponsoredProductsToCatalog'

export function combineSponsoredCatalogProducts(
  sponsoredProducts: SponsoredProducts,
  catalogProducts: Catalog
) {
  const sponsoredProductsId = sponsoredProducts?.data?.map(
    (product) => product.code
  )

  const catalogProductsWithoutSponsoredProducts = catalogProducts.data.filter(
    (product) => !sponsoredProductsId?.includes(product.code)
  )

  return {
    ...catalogProducts,
    meta: {
      ...catalogProducts.meta,
      totalItemsCount:
        catalogProducts.meta.totalItemsCount +
        (sponsoredProducts?.data?.length || 0)
    },
    data: [
      ...formatSponsoredProductsToCatalog(sponsoredProducts?.data),
      ...catalogProductsWithoutSponsoredProducts
    ] as Product[]
  }
}
