import { useEffect, useState } from 'react'
import Skeleton from 'react-loading-skeleton'

import Head from 'next/head'

import IconJustArrived from 'components/Icons/IconJustArrived'
import EmptyMessage from 'components/PageCatalogEmpty/EmptyMessage'
import { CatalogCarousel } from 'components/ProductsCarousel'
import CookiesInterface from 'interfaces/cookies'
import { search } from 'services/catalog'

import { useUser } from '@k_frontend/core'
import * as S from './styles'

const CatalogEmpty = (props) => {
  const { shouldRedirect, cookieIsMobile } = props
  const isPrime = useUser((state) => state.isPrime)
  const [justArrivedProducts, setJustArrivedProducts] = useState([])
  const [isLoadingJustArrived, setIsJustArrived] = useState(true)
  const [secondsToRedirect, setSecondsToRedirect] = useState(
    shouldRedirect ? 5 : 0
  )

  if (shouldRedirect) {
    if (secondsToRedirect <= 0) {
      window.location.replace('/')
    } else {
      setTimeout(() => {
        setSecondsToRedirect(secondsToRedirect - 1)
      }, 1000)
    }
  }

  const ClassCookiesInterface = new CookiesInterface()
  const headers = {
    'client-id': ClassCookiesInterface.getClientId(),
    session: ClassCookiesInterface.getSession()
  }

  useEffect(() => {
    function fetchJustArrived() {
      search({ sort: '-date_product_arrived', is_prime: isPrime }, headers)
        .then(({ data }) => {
          setJustArrivedProducts(data)
        })
        .catch((e) => console.error(JSON.stringify(e)))
        .finally(() => setIsJustArrived(false))
    }

    if (justArrivedProducts.length === 0) {
      fetchJustArrived()
    }
  }, [])

  const showCarousel = !isLoadingJustArrived && justArrivedProducts.length > 0

  return (
    <>
      <Head>
        <script id='SmartHint_PageType' type='application/javascript'>
          const SmartHint_PageType = 'search'
        </script>
      </Head>
      <S.PageGrid>
        <S.ContentGrid>
          <EmptyMessage
            subtitle={
              shouldRedirect
                ? `Você será redirecionado em ${secondsToRedirect} segundos...`
                : 'Tente novamente com outro termo para busca...'
            }
          />
        </S.ContentGrid>

        <S.CarouselGrid>
          <S.Carousel>
            {!showCarousel && (
              <S.CarouselSkeletonWrapper>
                <Skeleton width={214} height={400} count={6} />
              </S.CarouselSkeletonWrapper>
            )}
            {showCarousel && (
              <CatalogCarousel
                id='carousel-justArrived'
                list={justArrivedProducts}
                onProductClick={() => {}}
                header={{
                  icon: <IconJustArrived />,
                  title: 'Acabaram de chegar'
                }}
                showAll={{
                  link: '/acabaramdechegar'
                }}
                cookieIsMobile={cookieIsMobile}
              />
            )}
          </S.Carousel>
        </S.CarouselGrid>
      </S.PageGrid>
    </>
  )
}

export default CatalogEmpty
