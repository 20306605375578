import { ReviewModel } from 'models/customer/review/product/product.types'
import { QuestionsTypes } from 'models/institutional/faq'
import { KABUM_URL } from 'settings/kernel'
import { ProductType, ProductsType, BreadcrumbSchemaProps } from 'types/schema'

import { getTextFromHtml } from './html-parser'

interface GetGtmSchemaProductParams extends ProductType {
  productEvaluations: ReviewModel[]
}

export const getGtmSchemaProduct = (
  product: GetGtmSchemaProductParams
): string => {
  function ratingNumber() {
    if (product.ratingNumber > 0) {
      return `"aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": "${product.rating}",
          "reviewCount": "${product.ratingNumber}",
          "bestRating": "5",
          "worstRating": "1"
        },`
    }
    return ''
  }

  function reviews() {
    if (product.productEvaluations?.length > 0) {
      return `"review": [${product.productEvaluations.map(
        (review) => `{
            "@type": "Review",
            "author": {
              "@type": "Person",
              "name": "${review.customerName}"
            },
            "datePublished": "${review.date}",
            "reviewBody": "${review.description?.replace(/"/g, `\\"`)}",
            "name": "${product.name?.replace(/"/g, `\\"`)}",
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "${review.score}"
            }
        }`
      )}],`
    }

    return ''
  }

  return `{
    "@context": "https://schema.org",
    "@type": "Product",
    ${ratingNumber()}
    "description": "${getTextFromHtml(
      product.description?.replace(/"/g, `\\"`)
    )}",
    "name": "${product.name?.replace(/"/g, `\\"`)}",
    "image": "${product.imageUrl}",
    "sku": "${product.code}",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/${
        product.available ? 'InStock' : 'OutOfStock'
      }",
      "price": "${product.price}",
      "priceCurrency": "BRL",
      "url": "${KABUM_URL}/produto/${product.code}/${product.friendlyName}"
    },
    ${reviews()}
    "brand": {
      "@type": "Brand",
      "name": "${product.brand}"
    }
  }`
}

export const getGtmSchemaCatalogo = (
  products: ProductsType[],
  category: string
): string => {
  return `
  {
    "@context": "https://schema.org",
    "@type": "ItemList",
    "itemListElement": [
      ${products.map(
        (product, index) => `{
        "@type": "ListItem",
        "position": ${index + 1},
        "name": "${product.name?.replace(/"/g, '')}",
        "url": "${KABUM_URL}/produto/${product.code}/${product.friendlyName}"
      }`
      )}
    ],
    "itemListOrder": "https://schema.org/ItemListOrderDescending",
    "name": "Categoria ${category}"
  }
  `
}

export const getGtmSchemaFaq = (questions: QuestionsTypes[]) => {
  return `
  {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": [${questions?.map(
      (question) => `
      {
        "@type": "Question",
        "name": "${question.title}",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "${question.items.map((item) =>
            item.answer.map((answer) => answer.replace(/"/g, ''))
          )}"
        }
      }
    `
    )}]
  }
  `
}

export const getBreadcrumbSchema = (
  breadcrumb: BreadcrumbSchemaProps
): string => {
  return `{
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": [
      ${breadcrumb.map(
        (item, index) => `{
          "@type": "ListItem",
          "position": ${index + 1},
          "name": "${item.name}"
          ${item.item ? `,"item": "${item.item}"` : ''}
        }`
      )}
    ]
  }`
}
