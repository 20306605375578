import { Container } from '@k_frontend/ui'
import styled, { css } from 'styled-components'

export const CatalogQueryInfo = styled.div`
  display: none;
  align-items: center;
  ${({ theme }) => theme.font(14, 18, 400)};
  color: ${(props) => props.theme.colors.black(800)};
  margin: ${({ theme }) => theme.spacing.sm} 0;

  b {
    ${({ theme }) => theme.font(14, 18, 700)};
  }

  span,
  a,
  h1 {
    text-decoration: none;
    ${({ theme }) => theme.font(14, 18, 400)};
    color: ${(props) => props.theme.colors.black(800)};
  }

  a:hover {
    text-decoration: underline;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    display: flex;
    margin: ${({ theme }) => theme.spacing.lg} 0
      ${({ theme }) => theme.spacing.sm};
  }
`

export const ContainerCustom = styled(Container)`
  margin-bottom: ${({ theme }) => theme.spacing.md};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    margin-bottom: ${({ theme }) => theme.spacing.xxl};
  }
`

export const BannerListagem = styled.div<{
  heightMobile?: string
  heightTablet?: string
  heightDesktop?: string
}>`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;

  a {
    display: flex;
  }

  img {
    width: 100%;
    height: auto;
    max-height: ${({ heightMobile }) => heightMobile || 'auto'};
    object-fit: cover;

    @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
      max-height: ${({ heightTablet }) => heightTablet || 'auto'};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      max-height: ${({ heightDesktop }) => heightDesktop || 'auto'};
    }
  }
`

export const WrapperImage = styled.div<{ hasNoBanner: boolean }>`
  ${({ hasNoBanner }) => {
    if (hasNoBanner)
      return css`
        a {
          display: none;
        }
      `
  }}
`

export const SlugHighlight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${({ theme }) => theme.rem(1568)};
  height: ${({ theme }) => theme.rem(38)};
  background: ${(props) => props.theme.colors.secondary(500)};
  margin-bottom: ${({ theme }) => theme.spacing.xs};

  h1 {
    text-transform: capitalize;
    ${({ theme }) => theme.font(12, 24, 800)};
    color: ${(props) => props.theme.colors.white(500)};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    height: ${({ theme }) => theme.rem(48)};
    margin-bottom: ${({ theme }) => theme.spacing.sm};

    h1 {
      ${({ theme }) => theme.font(14, 24, 800)};
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    height: ${({ theme }) => theme.rem(70)};
    margin-bottom: ${({ theme }) => theme.spacing.lg};

    h1 {
      ${({ theme }) => theme.font(24, 34, 800)};
    }
  }
`

export const ContainerMainBanner = styled.div`
  display: flex;
  flex: 1;
`
